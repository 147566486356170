:root {
  --soft-black: #00000099;
  --box-shadow-height: 1px;
  --box-shadow: 0 var(--box-shadow-height) 4px -1px var(--soft-black);
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

/* Ant overrides */
.ant-layout abbr[title] {
  text-decoration: none;
}
.ant-layout abbr[title]:hover {
  text-decoration: underline dotted;
}
.ant-card-head-wrapper .ant-card-head-title {
  overflow: visible;
}

.react-datetime-picker .react-datetime-picker__button {
  box-shadow: var(--box-shadow);
  border: none;
  border-radius: 0.2em;
}
.react-datetime-picker input {
  box-shadow: none;
  padding: 0;
}
.react-datetime-picker
  .react-datetime-picker__button__input__input--hasLeadingZero {
  padding-left: calc(1px + 0.54em);
}
.react-dateime-picker .react-calendar__tile:disabled {
  cursor: not-allowed;
  color: darkgrey;
}
